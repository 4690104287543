
import { OpenAI } from "openai";


export const openai = () => {
  // const { apiKey } = useOpenAiStore.getState();
  //
  // if (!apiKey) {
  //   throw new Error(
  //     t`Your OpenAI API Key has not been set yet. Please go to your account settings to enable OpenAI Integration.`,
  //   );
  // }


  return new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true,
  });
};
