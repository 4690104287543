import { FaAngleDown } from 'react-icons/fa';
import { MdAdd, MdOpenInNew } from "react-icons/md";
import { Trans, useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React, { memo, useContext, useEffect, useState } from "react";
import cx from 'classnames';
import { useDispatch, useSelector } from '../../contexts/ResumeContext';
import { getFieldProps, handleKeyUp } from "../../utils";
import './popup.css'
import SettingsContext from '../../contexts/SettingsContext';
import {Calendar,DatePicker,} from "@progress/kendo-react-dateinputs";
import {IntlProvider,load,loadMessages,LocalizationProvider,} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/fr/numbers.json";
import caGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/fr/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/fr/timeZoneNames.json";
import esMessages from "../../i18n/locales/fr.json";
import { placeholder, styles } from "./placeholder";
import { Editor, EditorTools, EditorUtils, ProseMirror } from "@progress/kendo-react-editor";
import { improveWriting } from "../../services/openai/improve-writing";
import { changeTone } from "../../services/openai/change-tone";
import { Button } from "./Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  faEnvelope,
  faFaceGrinTongueWink,
  faFaceSmileWink,
  faPenToSquare,
  faSpellCheck,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import { RadioGroup, Radio } from '@progress/kendo-react-inputs';
import { fixGrammar } from "../../services/openai/fix-grammar";



let Action,Mood;
Action = "improve" | "fix" | "tone";
Mood = "casual" | "professional" | "confident" | "friendly";

load(likelySubtags,currencyData,weekData,numbers,caGregorian,dateFields,timeZoneNames);
loadMessages(esMessages, "fr");

export const CustomCalendar = (props) => {
  return (
    <Calendar
      bottomView="year"
      topView="decade"
      value={props.value}
      onChange={props.onChange}
    />
  );
};

// This is the node configuration


const onMount = (event) => {
  const state = event.viewProps.state;
  const plugins = [...state.plugins,placeholder("Vous pouvez içi définir les compétences clés que vous envisagez acquérir avec votre cursus en alternance ...")];
  const editorDocument = event.dom.ownerDocument;
  const styleElement = editorDocument && editorDocument.querySelector("style");

  if (styleElement) {
    styleElement.appendChild(editorDocument.createTextNode(styles));
  }

  return new ProseMirror.EditorView(
    {
      mount: event.dom,
    },
    {
      ...event.viewProps,
      state: ProseMirror.EditorState.create({
        doc: state.doc,
        plugins,
      }),
    }
  );
};



const Input = ({name,path,label,error,value, onBlur,options,touched,onClick,onChange,className,isRequired,placeholder,type = 'text',}) => {
  const { t } = useTranslation();
  const [uuid, setUuid] = useState(null);
  const stateValue = useSelector(path, '');
  const dispatch = useDispatch();
  const { language } = useContext(SettingsContext);

  const {Bold,Italic,Underline,OrderedList,UnorderedList,Undo,Redo,} = EditorTools;
  const editor = React.createRef();
  const textarea = React.createRef();
  let [improvedText, setImprovedText] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const [propositions, setPropositions] = useState([]);
  const [selectedProposition, setSelectedProposition] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const itemRender = (li, itemProps) => {
    return (
      <li {...li.props}>
        {itemProps.dataItem.icon}
        <span style={{ marginLeft: '10px',fontSize: '12px' }}>{li.props.children}</span>
      </li>
    );
  };

  const isEditorContentEmpty = (content) => {
    // Supprime toutes les balises HTML et vérifie si le contenu restant est vide
    const plainText = content.replace(/<[^>]*>/g, '').trim();
    return plainText === '';
  };
  const stripHtml = (html) => {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  const openAiToolSettings = {
    style: "font-size",
    defaultItem: { text: "Améliorer avec IA", value: "" },
    items: [
      { text: 'Orthographe', value: 'grammar', icon: <FontAwesomeIcon icon={faSpellCheck} /> },
      { text: 'Le texte', value: 'improve', icon: <FontAwesomeIcon icon={faPenToSquare} /> },
      { text: 'Ton décontracté', value: 'action2', icon: <FontAwesomeIcon icon={faFaceGrinTongueWink} /> },
      { text: 'Ton professional', value: 'action3', icon: <FontAwesomeIcon icon={faUserTie} /> },
      { text: 'Ton confiant', value: 'action4', icon: <FontAwesomeIcon icon={faFaceSmileWink} /> },
    ],
  };

// Creates FontSize tool
  const OpenAiTool =
    EditorTools.createStyleDropDownList(openAiToolSettings);

// Styles the FontSize tool
  const CustomOpenAi = (props) => (
    <OpenAiTool
      {...props}
      style={{
        width: "150px",
        fontSize: "14px",
        height: "30px",
        ...props.style,
      }}
      itemRender={itemRender}
      onChange={handleSelectChange}
    />
  );
  const processOpenAIResponse = (responseText) => {
    // Supposons que responseText est la chaîne contenant les propositions séparées par "---"
    const propositionsArray = responseText.split("---").map(text => text.trim());
    setPropositions(propositionsArray);
  };

  const handleSelectionChange = (e) => {
    setSelectedProposition(e.target.value);
  };

  // const setHtml = () => {
  //   console.log("Proposition sélectionnée : ", selectedProposition);
  //   // Logique pour traiter la proposition sélectionnée
  // };
  const getHtmlAndImprove = async (action, mood) => {
    if (!editor.current) {
      console.error("Référence à l'éditeur manquante");
      return;
    }

    const view = editor.current.view;
    if (!view) {
      console.error("Vue de l'éditeur introuvable");
      return;
    }
    console.log('dans fonction improve');
    try {
      const currentHtml = EditorUtils.getHtml(view.state);
      if (isEditorContentEmpty(currentHtml)) {
        console.log("Le contenu de l'éditeur est vide.");
              // Gérez le cas où le contenu est vide (par exemple, en affichant une alerte à l'utilisateur)
      } else {
        setIsLoading(true);
      if (action === "improve") improvedText = await improveWriting(currentHtml);
      if (action === "grammar") improvedText = await fixGrammar(currentHtml);
       if (action === "tone" && mood) improvedText = await changeTone(value, mood);
      //const improvedText = await improveWriting(currentHtml);
      console.log('text amelioré',improvedText);

        setIsLoading(false); // Arrête d'afficher l'icône de chargement
        processOpenAIResponse(stripHtml(improvedText));


        if (textarea.current) {
          textarea.current.value = stripHtml(improvedText);
        }
        setImprovedText(stripHtml(improvedText));
      }


    } catch (error) {
      console.error("Erreur lors de l'amélioration du texte : ", error);
    }
  };

  const handleSelectChange = (event) => {
    const action = event.value.value

    setSelectedAction(action);

    // Appeler la fonction correspondante à l'action
    switch (action) {

      case 'improve':
      getHtmlAndImprove('improve');
        break;
      case 'grammar':
        getHtmlAndImprove('grammar');
        break;
      case 'action2':
        getHtmlAndImprove('tone','casual');
        break;
      case 'action3':
        getHtmlAndImprove('tone','professional');
        break;
      case 'action4':
        getHtmlAndImprove('tone','confident');
        break;
      // Ajouter d'autres cas au besoin
      default:
        console.log('defaut');
      // Action par défaut ou rien
    }
  };

  const setHtml = () => {
    if (editor.current) {
      const view = editor.current.view;
      console.log("Proposition sélectionnée : ", selectedProposition);
      if (view) {
        //EditorUtils.setHtml(view, textarea.current.value);
        EditorUtils.setHtml(view, selectedProposition);
      }
    }
  };
  useEffect(() => {
    setUuid(uuidv4());
  }, []);

  value = path ? stateValue : value;

  onChange = isFunction(onChange)
    ? onChange
    : (e) => {
        dispatch({
          type: 'on_input',
          payload: {
            path,
            value: (type === 'textarea') ? e.html : e.target.value,
          },
        });
        setPropositions([]);
      };


  return (
    <div className={className}>
      <label htmlFor={uuid}>
        <span>
          {label}{' '}
          {isRequired && (
            <span className="opacity-75 font-normal lowercase">
              ({t('shared.forms.required')})
            </span>
          )}
        </span>

        { (type === 'date') && (
          <div className="relative grid items-center">
            <LocalizationProvider language={language}>
              <IntlProvider locale={language}>
                  <div className="relative grid items-center">
                    <DatePicker
                      calendar= {(`${name}` !== "birthDate") && CustomCalendar}
                      popupClass="k-animation-container"
                      id={uuid}
                      name={name}
                      value= {`${value}` !== "" && new Date(`${value}`) }
                      onBlur={onBlur}
                      format= {(`${name}` !== "birthDate") ? "MMM yyyy" : "dd/MMM/yyyy"}
                      onChange={onChange}
                    />
                  </div>

              </IntlProvider>
            </LocalizationProvider>
          </div>
        )}

        {(type === 'text') && (
          <div className="relative grid items-center">
            <input
              id={uuid}
              name={name}
              type={type}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
            />
          </div>
        )}

        {(type === 'password') && (
          <div className="relative grid items-center">
            <input
              id={uuid}
              name={name}
              type={type}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
            />
          </div>
        )}


        {type === 'textarea' && (
          <div className="flex flex-col">

            <Editor
              defaultContent={value}
              value={value}
              onMount={onMount}
              defaultEditMode="div"
              onChange={onChange}
              tools={[
                [Bold, Italic, Underline],
                [Undo, Redo],
                [CustomOpenAi],

              ]}
              contentStyle={{
                height: 100
              }}
              ref={editor}

            />
            <br />
            {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
            {/*{improvedText && (*/}
              <div>
                {isEditorContentEmpty(value) ? (
                 <p style={{ color: "red" }}>Veuillez saisir du contenu dans l'éditeur avant de soumettre pour l'amélioration.</p>
                 ) :
                  (<div>
                      {propositions.length > 0 && propositions.map((proposition, index) => (
                        <div key={index} className="k-radio-wrapper" style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                          <input
                            type="radio"
                            className="k-radio"
                            id={`proposition-${index}`}
                            name="proposition"
                            value={proposition}
                            onChange={handleSelectionChange}
                            checked={selectedProposition === proposition}
                          />
                          <label className="k-radio-label" htmlFor={`proposition-${index}`} style={{ marginLeft: "10px" }}>
                            {proposition}
                          </label>
                        </div>
                      ))}

                      {propositions.length > 0 && (
                        <button
                          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                          onClick={setHtml}
                          disabled={!selectedProposition}
                        >
                          <span className="k-icon k-i-arrow-60-up" />
                          Sélectionner une proposition et valider içi
                        </button>
                      )}
                    </div>
                  )}
              </div>
          </div>
        )}

        {type === "dropdown" && (
          <div className="relative grid items-center">
            <select
              id={uuid}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}

            >
              {options.map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>

            <FaAngleDown
              size="16px"
              className="absolute right-0 opacity-50 hover:opacity-75 mx-4"
            />
          </div>
        )}

        {type === "color" && (
          <div className="relative grid items-center">
            <div className={styles.circle} style={{ backgroundColor: value }} />

            <input
              id={uuid}
              name={name}
              type="text"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
            />
          </div>
        )}

        {type === 'action' && (
          <div className={cx('relative grid items-center', styles.readOnly)}>
            <input readOnly id={uuid} name={name} type="text" value={value} />

            <div
              tabIndex="0"
              role="button"
              onClick={onClick}
              onKeyUp={(e) => handleKeyUp(e, onClick)}
            >
              <MdOpenInNew size="16px" />
            </div>
          </div>
        )}

        {error && touched && <p>{error}</p>}
      </label>
    </div>
  );
};

export default memo(Input);
